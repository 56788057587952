@tailwind base;

@tailwind components;

@tailwind utilities;

.min-h-screen-90 {
    min-height: 90vh;
}

.green-custom{
    color: rgba(22, 192, 141, 1);
}
.blue-custom{
    color:rgba(30, 64, 175,1);
}

.h-img{
    height: 64px;
    width: 64px;
}

.pb-table{
    padding-bottom: 40px !important;
}

.tl-fixed{
    table-layout: fixed;
}

.mytbody{
    display: block !important;
    width: 100% !important;
    height: 500px !important;
    overflow: auto !important;
}


.col-date{
    width:8% !important
}

.col-name{
    width: 15% !important
}

.col-sexe{
    width:5%  !important
}


.col-poste{
    width: 10% !important
}

.col-contact{
    width: 16% !important
}

.col-place{
    width: 12% !important
}

.col-document{
    width:7% !important
}
.col-action{
    width:7% !important
}

.scroll-select{
    display:block !important    ;
    height:200px !important ;
    overflow: scroll  !important
}

.scroll-select-2{
    display:block !important    ;
    height:200px !important ;
    overflow: scroll  !important;
    min-width: 120px  !important;
}

.text-red-500{
    color: rgba(239, 68, 68,1) !important
}

.height-container-graphs{
    height: 550px !important
}

.height-graphs{
    height: 550px !important;
    background-color: white !important;
}

.height-in-graphs{
    height: 440px !important;
    background-color: white !important;
}

.text-green-500{
    color: rgba(34, 197, 94,1) !important
}

.col-num{
    width: 3% !important;
}

.col-ref{
    width: 14%;
}

.col-objet{
    width: 16%
}

.col-exp{
    width:10%
}

.radio-out{
height: 18px !important;
width: 18px !important;
border-radius: 18px !important;
background-color: white !important;
border-color: black !important;
border-width: 1px;
display: flex !important;
justify-content: center !important;
align-items: center !important;
align-content: center !important;
}

.radio-in{
    height: 12px !important;
    width: 12px !important;
    border-radius: 12px !important;
    background-color: white !important;
    
}

.radio-in-selected{
    height: 12px !important;
    width: 12px !important;
    border-radius: 12px !important;
    background-color: blue !important;
}

.center-content{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
}
